export interface ITask {
  status: TaskStatus;
  pos: number;
  title: string;
  url: string;
  timeMs: number;
}

export enum TaskStatus {
  ACTIVE = 0,
  COMPLETE = 1,
  PENDING = 2,
  DISABLED = 3,
}

export enum Time {
  MILLISECOND = 1,
  SECOND = 1000,
  MIN = 1000 * 60,
  HOURS = 1000 * 60 * 60,
  DAY = 1000 * 60 * 60 * 24,
  WEEK = 1000 * 60 * 60 * 24 * 7,
  MONTH = 1000 * 60 * 60 * 24 * 30,
  YEAR = 1000 * 60 * 60 * 24 * 365,
}
