import React, { ChangeEvent } from "react";
import "./App.css";
import { ITask, TaskStatus, Time } from "./interfaces";
import { useState } from "react";
import TodoItem from "./components/TodoItem";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Row, Col, Container } from "react-bootstrap";

// const dummyTodos = [
//   {
//     pos: 0,
//     status: TaskStatus.ACTIVE,
//     title: "test 1",
//     timeMs: 2 * 60 * 1000,
//     url: "https://www.google.com/",
//   },
//   {
//     pos: 1,
//     status: TaskStatus.PENDING,
//     title: "test 2",
//     timeMs: 5 * 60 * 1000,
//     url: "https://www.google.com/",
//   },
//   {
//     pos: 2,
//     status: TaskStatus.PENDING,
//     title: "test 3",
//     timeMs: 15 * 60 * 1000,
//     url: "https://www.google.com/",
//   },
// ];

const App: React.FC = () => {
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [timeIntervalFunction, setTimeIntervalFunction] = useState<any>(null);

  const [taskTitle, setTaskTitle] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [pos] = useState<number>(0);
  const [timeMs, setTimeMs] = useState<number>(1);
  const [todo, setTodo] = useState<ITask[]>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (event.target.name === "task") {
      setTaskTitle(val);
    }
    if (event.target.name === "timeMs") {
      setTimeMs(Number(val));
    }
    if (event.target.name === "url") {
      setUrl(val);
    }
  };
  const addTask = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const actives = todo.filter((t) => t.status === TaskStatus.ACTIVE);
    const status = actives.length ? TaskStatus.PENDING : TaskStatus.ACTIVE;
    const pos = Date.now();
    const newTask = {
      title: taskTitle,
      status,
      url,
      pos,
      timeMs: timeMs * Time.MIN,
    };
    setTodo([...todo, newTask]);
    setTimeMs(1);
    setUrl("");
    setTaskTitle("");
  };

  const completedTaskTimes = () => {
    return todo.reduce((accum: number, current: ITask): number => {
      if (current.status === TaskStatus.COMPLETE) {
        return accum + current.timeMs;
      }
      return accum;
    }, 0);
  };

  const elapsedTimeMinusTasks = () => {
    const completedTaskTimes = todo.reduce(
      (accum: number, current: ITask): number => {
        if (current.status === TaskStatus.COMPLETE) {
          return accum + current.timeMs;
        }
        return accum;
      },
      0
    );
    return elapsedTime - completedTaskTimes;
  };

  const updateTimer = (title: string, pos: number, increase: boolean) => {
    const tasks = todo.map((t) => {
      if (t.pos === pos && TaskStatus.COMPLETE !== t.status) {
        if (increase) {
          t.timeMs = Math.min(t.timeMs + Time.MIN, Time.HOURS * 24);
        } else {
          t.timeMs = Math.max(t.timeMs - Time.MIN, 1);
        }
      }
      return t;
    });
    setTodo([...tasks]);
  };

  const startTimer = () => {
    if (timeIntervalFunction) return;
    const time = Time.SECOND;
    const interval = setInterval(() => {
      setElapsedTime((elapsedTime) => {
        return elapsedTime + time;
      });
    }, time);
    setTimeIntervalFunction(interval);
  };

  const stopTimer = () => {
    clearInterval(timeIntervalFunction);
    setTimeIntervalFunction(null);
  };
  const resetTimer = () => {
    setElapsedTime(0);
  };

  const completeTask = (titleToDelete: string) => {
    const todos = todo.filter((t) => t.title !== titleToDelete);
    setTodo(todos);
  };

  const handleDirection = (direction: number, pos: number) => {
    const itemIndex = todo.findIndex((t) => t.pos === pos);
    const newTodos = todo;
    if (direction === 1) {
      const temp = newTodos[itemIndex - 1];
      if (!temp) return;
      const current = newTodos[itemIndex];

      const tempStatus = temp.status;
      const currentStatus = current.status;
      current.status = tempStatus;
      temp.status = currentStatus;

      newTodos[itemIndex - 1] = current;
      newTodos[itemIndex] = temp;
    } else {
      const temp = newTodos[itemIndex + 1];
      if (!temp) return;
      const current = newTodos[itemIndex];

      const tempStatus = temp.status;
      const currentStatus = current.status;
      current.status = tempStatus;
      temp.status = currentStatus;

      newTodos[itemIndex + 1] = current;
      newTodos[itemIndex] = temp;
    }
    setTodo([...newTodos]);
  };

  const collapseTask = (pos: number) => {
    setTimeout(() => {
      const taskIndex = todo.findIndex((t) => t.pos === pos);
      const task = todo[taskIndex];
      if (!task) return;
      task.status = TaskStatus.COMPLETE;
      const nextTask = todo[taskIndex + 1];
      if (nextTask) {
        nextTask.status = TaskStatus.ACTIVE;
      }
      const todosLeft = todo.filter((t) => t.status === TaskStatus.ACTIVE);
      if (todosLeft.length === 0) {
        stopTimer();
      }
    }, 500);
  };

  const displayTime = () => {
    let seconds = Math.floor((elapsedTime / Time.SECOND) % 60),
      minutes = Math.floor((elapsedTime / Time.MIN) % 60),
      hours = Math.floor((elapsedTime / Time.HOURS) % 24);

    const hoursStr = hours < 10 ? "0" + hours : hours;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const secondsStr = seconds < 10 ? "0" + seconds : seconds;

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  };

  return (
    <div className="App">
      <div className="header">
        <div className="inputContainer">
          <Container>
            <Row>
              <Col xs={12}>
                {" "}
                <div className="legend">
                  <div className="buttons">
                    <Button
                      color="yellowgreen"
                      variant="outline-primary"
                      className="button"
                      onClick={startTimer}
                    >
                      Start
                    </Button>
                    <Button
                      className="button"
                      variant="outline-secondary"
                      onClick={stopTimer}
                    >
                      Stop
                    </Button>
                    <Button
                      className="button"
                      variant="outline-warning"
                      onClick={resetTimer}
                    >
                      Reset
                    </Button>
                  </div>
                  <div className="timer-container">
                    <h1 className="timer">{displayTime()}</h1>
                  </div>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </Container>

          <Form className="new-item" onSubmit={addTask}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                name="task"
                value={taskTitle}
                onChange={handleChange}
                placeholder="Enter title"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                name="url"
                value={url}
                onChange={handleChange}
                placeholder="Enter link"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <input
                type="range"
                min="1"
                max="100"
                name="timeMs"
                className="time-range"
                onChange={handleChange}
                value={timeMs}
              />{" "}
            </Form.Group>
            <div className="bottom-form">
              <h3>{timeMs} Minutes</h3>
              <Button type="submit" variant="outline-primary">
                Add
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className="todoList">
        {todo.map((task: ITask, key: number) => (
          <TodoItem
            key={key}
            task={task}
            elapsedTime={elapsedTime}
            completedTaskTimes={completedTaskTimes()}
            completeTask={completeTask}
            updateTimer={updateTimer}
            collapseTask={collapseTask}
            handleDirection={handleDirection}
          />
        ))}
      </div>
    </div>
  );
};

export default App;

/**
 *
 * timeElapsed: 0
 * startDateTime: <click start button>
 *
 *
 */
