import { ITask, TaskStatus, Time } from "../interfaces";
import "./TodoItem.scss";
import { ProgressBar } from "react-bootstrap";
import sound from "./media/ding.wav";

enum Directions {
  UP = 1,
  DOWN = 2,
}

interface Props {
  task: ITask;
  completeTask(titleToDelete: string): void;
  collapseTask(posToDelete: number): void;
  updateTimer(taskTitle: string, pos: number, flag: boolean): void;
  handleDirection(direction: number, pos: number): void;
  elapsedTime: number;
  completedTaskTimes: number;
}

const TodoItem = ({
  task,
  completeTask,
  collapseTask,
  updateTimer,
  elapsedTime,
  completedTaskTimes,
  handleDirection,
}: Props) => {
  const remainingTime = () => {
    return elapsedTime - completedTaskTimes;
  };
  const percentage = () => {
    if (task.status === TaskStatus.COMPLETE) return 100;
    if (task.status !== TaskStatus.ACTIVE || remainingTime() === 0) return 0;
    const percent = (remainingTime() / task.timeMs) * 100;
    if (percent === 100) {
      playSound();
      collapseTask(task.pos);
    }
    return percent <= 100 ? percent : 100;
  };

  const getStatus = () => {
    if (task.status === 0) {
      return "active";
    }
    if (task.status === 1) {
      return "complete";
    }
    if (task.status === 2) {
      return "pending";
    }
    if (task.status === 3) {
      return "disabled";
    }
  };

  const formatTime = (time: number) => {
    let minutes = time / Time.SECOND / 60;
    let seconds = Math.floor((time / Time.SECOND) % 60);
    let format = "";
    if (minutes >= 60) {
      const hour = Math.floor(minutes / 60);
      minutes -= 60;
      format += `${hour}:`;
    }
    let min = Math.floor(minutes % 60);
    format += `${min}:${seconds < 10 ? "0" + seconds : seconds}`;
    return format;
  };

  const getRemainingTimeFormat = () => {
    console.log({ elapsedTime, completedTaskTimes });
    if (task.status === TaskStatus.ACTIVE) {
      return formatTime(Math.max(task.timeMs - remainingTime(), 0));
    } else {
      return formatTime(task.timeMs);
    }
  };

  const playSound = () => {
    new Audio(sound).play();
  };

  const getURL = () => {
    try {
      const u = new URL(task.url);
      return u.origin;
    } catch (e) {
      // console.log("Invalid URL");
      return "";
    }
  };

  return (
    <div className={"task " + getStatus()}>
      <div className="content">
        <div className="title">
          {task.title.toUpperCase()}
          <span className={"status " + getStatus()}>{getStatus()}</span>
          <span>
            <span
              className="decrease"
              onClick={() => updateTimer(task.title, task.pos, false)}
            >
              -
            </span>
            <span className="time">{getRemainingTimeFormat()}</span>
            <span
              className="increase"
              onClick={() => updateTimer(task.title, task.pos, true)}
            >
              +
            </span>
          </span>
        </div>

        <div className="url">
          <a target="_blank" href={task.url} rel="noreferrer">
            {getURL()}
          </a>
        </div>
        <ProgressBar className="progress-container" now={percentage()} />
      </div>
      <div className="task-buttons">
        <button
          className="delete"
          onClick={() => {
            completeTask(task.title);
          }}
        >
          X
        </button>
        <i
          className="arrow up"
          onClick={() => handleDirection(Directions.UP, task.pos)}
        ></i>
        <i
          className="arrow down"
          onClick={() => handleDirection(Directions.DOWN, task.pos)}
        ></i>
      </div>
    </div>
  );
};

export default TodoItem;
